import React, {useEffect, useState} from "react";
import constants from "../components/util/constants";
import CustomImage from "../components/util/CustomImage";
import IbatApi from "../utils/IbatApi";

const api=new IbatApi()
export default function OperationPicture({filePath,id,layer,visibleStyleClass,visible,width,height,onClick,onRightClick, url}){
    const [visibility,setVisibility]=useState(visible)
    function handleVisible(){
        api.hasRole(constants.PERMISSION_CRE) && setVisibility(!visibility)
        api.hasRole(constants.PERMISSION_CRE) && onRightClick&&onRightClick(visible,id)
    }

    useEffect(()=>{

    }, [url])

    console.log(url)
    // console.log(url)

    return(
            <div onClick={()=>onClick(filePath)}>
                <CustomImage url={url} onRightClick={handleVisible} layerName={layer} width={width} height={height} name={filePath} className={!visibility?visibleStyleClass:""} />
            </div>
    )

}